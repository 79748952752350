<template>
    <section>
        <v-data-table :headers="headers" flat :items="customers.data" :items-per-page="10">
            <template v-slot:item.name="{item}">
                <h1 class="mb-0 mt-5 mt-sm-0"
                    @click="$router.push('customers/'+item.id)">
                    {{item.first_name}} {{item.last_name}}
                </h1>
                <p class="ma-0"><small>{{item.email}}</small></p>
            </template>

            <template v-slot:item.status="{item}">
                <v-chip>{{item.status}}</v-chip>
            </template>

            <template v-slot:item.address="{item}">
                <code>Phone</code>
                {{item.phone}} {{item.mobile}} <br>
                <code>Address</code>
                {{item.city}}, {{item.street}} / {{item.zip}}
            </template>

            <template v-slot:item.contacts="{item}">
                <div v-for="contact in item.contacts" v-if="contact.type !== 'billing'" class="my-sm-1 my-2">
                    <code>{{contact.type}}</code>
                    <br><b>{{contact.first_name}}</b>
                    {{contact.phone}} {{contact.mobile}}
                </div>
            </template>

            <!-- Nurse -->
            <!--            <template v-slot:item.nurse="{item}">-->
            <!--                <section class="hidden-xs-only">-->
            <!--                    <div v-if="item.nurse" class="hidden-xs-only">-->
            <!--                        <h1 class="mb-0 text-xl-h6">{{item.nurse.name}}</h1>-->
            <!--                        <p class="ma-0"><small>{{item.nurse.email}}</small></p>-->
            <!--                    </div>-->
            <!--                    <div v-else>-->
            <!--                        <h1 class="mb-0 text-xl-h6">Unassigned</h1>-->
            <!--                    </div>-->
            <!--                </section>-->
            <!--            </template>-->

            <!--            <template v-slot:item.created_at="{item}">-->
            <!--                <div class="mb-5 my-sm-6">{{item.created_at | moment("DD MMM YY") }}</div>-->
            <!--            </template>-->
            <template v-slot:item.controls="{item}">
                <v-btn class="hidden-xs-only" @click="$router.push('customers/'+item.id)">View</v-btn>
            </template>
        </v-data-table>

        <v-pagination
                v-model="page"
                @input="paginationChange"
                :length="customers.last_page"
                :total-visible="6"
        ></v-pagination>
    </section>
</template>
<script>
    import customers from "../../../../../api/modules/customers";
    import {EventBus} from "../../../../../bootstrap/EventBus";

    export default {
        name: 'CustomersTable',
        data: () => ({
            page: 1,
            headers: [
                {text: 'Name', value: 'name'},
                {text: 'Status', value: 'status'},
                {text: 'Contact', value: 'address'},
                {text: 'Contacts', value: 'contacts'},
                {text: 'Controls', value: 'controls'},
            ],
            customers: {
                last_page: 1
            },
            filters: {
            },
        }),
        created() {
            this.filters = {};

            this.fetchCustomers();

            EventBus.$on('customers-filters', filters => {
                this.page = 1;
                this.filters = filters;
                this.fetchCustomers();
            })
        },
        methods: {
            fetchCustomers: function () {
                customers.fetchCustomers(this.filters).then(res => {
                    this.customers = res.data;
                })
            },
            paginationChange: function () {
                this.filters.page = this.page;
                this.fetchCustomers();
            },
        },
        beforeDestroy() {
            EventBus.$off('customers-filters');
        }
    }
</script>
