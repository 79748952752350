<template>
    <section id="users-list-page">
        <v-col md="10" xs="12">
            <v-card>
                <v-card-title>
                    {{$t('navigation.customers')}}
                    <v-btn class="ml-auto hidden-xs-only" color="primary" @click="$router.push({name: 'customers.create'})">
                        Add Customer
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-sheet>
                        <customers-filters />
                        <customers-table/>
                    </v-sheet>
                </v-card-text>
            </v-card>
        </v-col>
    </section>
</template>

<script>
    import CustomersTable from "./components/CustomersTable";
    import CustomersFilters from "./components/CustomersFilters";

    export default {
        name: 'UsersList',
        components: {CustomersFilters, CustomersTable}
    }
</script>
