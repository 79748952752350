var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-data-table',{attrs:{"headers":_vm.headers,"flat":"","items":_vm.customers.data,"items-per-page":10},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('h1',{staticClass:"mb-0 mt-5 mt-sm-0",on:{"click":function($event){return _vm.$router.push('customers/'+item.id)}}},[_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" ")]),_c('p',{staticClass:"ma-0"},[_c('small',[_vm._v(_vm._s(item.email))])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(_vm._s(item.status))])]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('code',[_vm._v("Phone")]),_vm._v(" "+_vm._s(item.phone)+" "+_vm._s(item.mobile)+" "),_c('br'),_c('code',[_vm._v("Address")]),_vm._v(" "+_vm._s(item.city)+", "+_vm._s(item.street)+" / "+_vm._s(item.zip)+" ")]}},{key:"item.contacts",fn:function(ref){
var item = ref.item;
return _vm._l((item.contacts),function(contact){return (contact.type !== 'billing')?_c('div',{staticClass:"my-sm-1 my-2"},[_c('code',[_vm._v(_vm._s(contact.type))]),_c('br'),_c('b',[_vm._v(_vm._s(contact.first_name))]),_vm._v(" "+_vm._s(contact.phone)+" "+_vm._s(contact.mobile)+" ")]):_vm._e()})}},{key:"item.controls",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"hidden-xs-only",on:{"click":function($event){return _vm.$router.push('customers/'+item.id)}}},[_vm._v("View")])]}}])}),_c('v-pagination',{attrs:{"length":_vm.customers.last_page,"total-visible":6},on:{"input":_vm.paginationChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }